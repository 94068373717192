import React from "react"
import styled from "styled-components"
import Seo from "../components/seo"
import Layout from "../components/layout"
import sparkles from "../images/sparkles.png"

const Copy = styled.main`
  padding: 8rem 0;
  background: var(--grey);
  h1 {
    text-align: center;
    font-size: 9.6rem;
    font-weight: 400;
    padding: 4.8rem 0;
    font-family: "tahu";
    color: #e8b795;
    @media (max-width: 767px) {
      font-size: 6.5rem;
      line-height: 6.5rem;
    }
  }
  h2 {
    font-size: 2.4rem;
    text-transform: uppercase;
    padding-bottom: 2.4rem;
  }
  p,
  li {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  ul {
    list-style-type: square;
    list-style-position: inside;
    padding-bottom: 1.6rem;
  }
  li {
    padding-bottom: 1rem;
  }
  img {
    margin-bottom: 0px;
    height: 16px;
  }
  .greeting {
    font-weight: 700;
    font-size: 2.4rem;
  }
`

const Form = styled.div`
  max-width: 60rem;
  margin: 6rem auto;
  .cta {
    font-size: 2.4rem;
    padding-bottom: 1rem;
    text-transform: uppercase;
  }
  .grid-2x {
    margin-bottom: 0;
    gap: 1rem;
  }
  @media (max-width: 767px) {
    .grid-2x {
      gap: 0;
    }
  }
  .btn {
    margin-top: 0rem;
    border: none;
  }
  input {
    display: block;
    width: 100%;
    font-size: 1rem;
    padding: 2.4rem;
    margin-bottom: 1rem;
    font-size: 1.6rem;
    border: 2px solid #000;
    background: transparent;
  }
  input[type="submit"] {
    background: var(--black);
  }
  input[type="submit"]:hover {
    background: #e8b795;
  }
  input::placeholder,
  textarea::placeholder {
    font-size: 1.6rem;
    font-weight: 300;
    font-style: italic;
    text-transform: uppercase;
  }
`

const PartnershipsPage = () => {
  return (
    <Layout>
      <Seo
        title="Parcerias"
        description="Queres fazer parceria com a Boomsy? Preenche o formulário e junta-te ao nosso programa de afiliados."
      />
      <Copy>
        <div className="container">
          <p className="greeting">Olá!</p>
          <p>
            Bem-vind@ à area de parcerias Boomsy{" "}
            <img src={sparkles} alt="sparkles icon" />
          </p>
          <p>
            A Boomsy é a primeira marca portuguesa de Pestanas Magnéticas com
            Eyeliner Magnético. Acreditamos que as pestanas postiças são para
            todos e decidimos facilitar o processo: dissemos{" "}
            <strong>adeus à cola</strong> e{" "}
            <strong>olá ao poder do magnetismo</strong>. Todas as nossas
            pestanas são de alta qualidade e dão para mais de 35 utilizações.
          </p>
          <p>
            A melhor parte? Todos os nossos produtos são vegan e cruelty free!
          </p>
          <h1>Parcerias Boomsy</h1>
          <h2>
            As <span style={{ fontWeight: 700 }}>Vantagens</span>
          </h2>
          <ul>
            <li>Descontos exclusivos em todos os nossos produtos</li>
            <li>Percentagem de desconto para os teus seguidores </li>
            <li>
              Ganho sobre todas as vendas que sejam efetuadas com o teu código
              de desconto
            </li>
            <li>
              Acesso a todas as novidades da marca antes dos lançamentos
              oficiais
            </li>
          </ul>
          <h2 style={{ marginTop: "2.4rem" }}>
            As tuas <span style={{ fontWeight: 700 }}>Responsabilidades</span>
          </h2>
          <ul>
            <li>
              Seguir a Boomsy no{" "}
              <a
                href="https://www.instagram.com/boomsylashes/"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </li>
            <li>
              Promover os nossos produtos com partilhas de fotos e/ou vídeos,
              identificando sempre o perfil da Boomsy (@boomsylashes)
            </li>
          </ul>
          <p>
            Se acreditas no poder do magnetismo e gostavas de fazer parte do
            nosso programa de afiliados, preenche o formulário abaixo:
          </p>
          <Form>
            <p className="cta">
              Torna-te <span style={{ fontWeight: 700 }}>Fabulash</span>!
            </p>
            <form
              id="contactform"
              name="contactform"
              method="POST"
              action="https://formsubmit.co/info@boomsy.pt"
            >
              <div className="grid-2x">
                <input
                  type="text"
                  placeholder="Nome*"
                  id="name"
                  name="Nome"
                  required={true}
                />
                <input
                  type="text"
                  placeholder="Apelido*"
                  id="surname"
                  name="Apelido"
                  required={true}
                />
              </div>
              <input
                type="email"
                placeholder="E-mail*"
                id="email"
                name="Email"
                required={true}
              />
              <input
                type="text"
                placeholder="User Instagram*"
                id="instagram"
                name="Instagram"
                required={true}
              />
              <input
                name="_formsubmit_id"
                type="text"
                style={{ display: "none" }}
              />
              <input
                type="hidden"
                name="_next"
                value="https://boomsy.pt/obrigado"
              />
              <input type="hidden" name="_captcha" value="false" />
              <input type="submit" className="btn" value="ENVIAR" />
            </form>
          </Form>
        </div>
      </Copy>
    </Layout>
  )
}

export default PartnershipsPage
